<template>
  <div class="main-box">
    <el-form class="form-inline" :inline="true">
      <el-form-item label="">
        <el-input v-model="search.keywords" placeholder="请输入关键词" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-select v-model="search.reply_type" clearable placeholder="请选择图文类型" size="small">
          <el-option :key="1" label="文本" :value="1"></el-option>
          <el-option :key="2" label="图文" :value="2"></el-option>
          <el-option :key="3" label="图片" :value="3"></el-option>
<!--          <el-option :key="4" label="音频" :value="4"></el-option>-->
<!--          <el-option :key="5" label="视频" :value="5"></el-option>-->
<!--          <el-option :key="6" label="视频号" :value="6"></el-option>-->
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-select v-model="search.status" clearable placeholder="请选择上架状态" size="small">
          <el-option
              v-for="item in statusList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="handleSearch()">搜索</el-button>
      </el-form-item>
    </el-form>
    <div class="mg-bt-10">
      <el-button type="primary" size="small" icon="el-icon-plus" @click="addGroup()">添加</el-button>
      <el-button type="danger" size="small" icon="el-icon-delete" @click="delGroup()">删除</el-button>
    </div>
    <el-table
        :data="tableData"
        ref="rfTable"
        style="width: 100%"
        size="mini"
        @selection-change="handleSelectionChange">
      <el-table-column
          type="selection"
          align="center"
          width="55">
      </el-table-column>
      <el-table-column
          prop="reply_type_str"
          label="类型"
          align="center"
          width="100">
      </el-table-column>
      <el-table-column
          prop="keywords"
          label="关键词"
          width="150">
      </el-table-column>
      <el-table-column
          prop="reply"
          label="内容"
          align="left"
          min-width="300">
        <template slot-scope="scope" v-if="scope.row.reply">
          <div v-if="scope.row.reply_type==1" v-html="scope.row.reply.text"></div>
          <div v-if="scope.row.reply_type==2 || scope.row.reply_type==3">
            <el-avatar shape="square" :size="60" :src="scope.row.reply.img"></el-avatar>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          prop="is_default"
          label="默认回复"
          align="center"
          width="150">
        <template slot-scope="scope">
          <el-switch :value="scope.row.is_default==1" @change="changeIsDefault(scope.row.id,scope.row.is_default==1?0:1)" active-text="关闭" inactive-text="启动"></el-switch>
        </template>
      </el-table-column>
      <el-table-column
          prop="is_follow"
          label="关注回复"
          align="center"
          width="150">
        <template slot-scope="scope">
          <el-switch :value="scope.row.is_follow==1" @change="changeIsFollow(scope.row.id,scope.row.is_follow==1?0:1)" active-text="关闭" inactive-text="启动"></el-switch>
        </template>
      </el-table-column>
      <el-table-column
          prop="status"
          label="上架状态"
          align="center"
          width="150">
        <template slot-scope="scope">
          <el-switch :value="scope.row.status==1" @change="changeStatus(scope.row.id,scope.row.status==1?0:1)" active-text="下架" inactive-text="上架"></el-switch>
        </template>
      </el-table-column>
      <el-table-column
          prop="ftime"
          label="添加时间"
          align="center"
          width="140">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          align="center"
          width="100">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="editGroup(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, prev, pager, next, jumper"
        :page-sizes="[10, 20, 30, 40]"
        :total="search.total">
    </el-pagination>
  </div>
</template>
<script>

export default {
  components: {
  },
  data() {
    return {
      info: false,
      source_domain: this.config.SOURCE_DOMAIN,
      search: {
        current_page: 1,
        total: 0,
        limit: 10,
        keywords: '',
        status: '',
        reply_type: ''
      },
      tableData: [],
      statusList: [
        {id:1,title:'上架'},
        {id:2,title:'下架'},
      ]
    };
  },
  created() {
    this.initData();
  },
  mounted() {
  },
  computed: {},
  methods: {
    initData() {
      this.getList();
    },
    getList() {
      var that = this
      that.showLoading()
      var param = {
        page: that.search.current_page,
        page_size: that.search.limit,
        keywords: that.search.keywords,
        status: that.search.status,
        reply_type: that.search.reply_type
      }
      this.$api.channel.weixinReplyIndex(param, function (res) {
        that.hideLoading()
        if (res.errcode == 0) {
          that.tableData = res.data.data
          that.search.current_page = res.data.current_page
          that.search.total = res.data.total
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    addGroup() {
      this.$router.push({name: 'channel_weixin_pic_edit'})
    },
    editGroup(item) {
      this.$router.push({name: 'channel_weixin_pic_edit', query: {id: item.id}})
    },
    changeStatus(id,status) {
      this.$api.channel.weixinReplyShenhe({id: id, status: status}, res => {
        if (res.errcode == 0) {
          this.success(res.errmsg)
          this.getList()
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    changeIsDefault(id,status) {
      this.$api.channel.weixinReplyIsDefault({id: id,status: status}, res => {
        if (res.errcode == 0) {
          this.success(res.errmsg)
          this.getList()
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    changeIsFollow(id,status) {
      this.$api.channel.weixinReplyIsFollow({id: id,status: status}, res => {
        if (res.errcode == 0) {
          this.success(res.errmsg)
          this.getList()
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    delGroup() {
      var that = this
      var sel = this.$refs.rfTable.selection
      if (sel.length < 1) {
        that.tips('请选择删除项')
        return false
      }
      var ids = []
      sel.forEach(function (item) {
        ids.push(item.id)
      })
      this.$confirm('确认要删除这些数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.channel.weixinReplyDel({ids: ids}, function (res) {
          if (res.errcode == 0) {
            that.success('删除成功')
            that.getList()
          } else {
            that.fail(res.errmsg)
          }
        })
      }).catch(() => {

      });
    },
    handleSearch() {
      this.search.current_page = 1
      this.getList()
    },
    handleSelectionChange(rows) {
    },
    handleCurrentChange(val) {
      this.search.current_page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.current_page = 1
      this.search.limit = val
      this.getList()
    }
  }
};
</script>
<style scoped>
</style>
